:root {
  --base-white: #fff;
  --black: #000000;
  --secondary-white: #F8F9FD;
  --grey-main: #101828;
  --grey-secondary: #344054;
  --grey-third: #FAF9FF;
  --grey-text: #667085;
  --prpl-main: #6050A6;
  --prpl-secondary: #EEEBF8;
  --prpl-3: #F3F1FF;
  --footer: #222222;
  --grey9: #EAECF0;
  --grey8: #444444;
  --grey7: #555555;
  --grey6: #777777;
  --grey5: #a3a3a3;
  --grey4: #d7d7d7;
  --grey3: #d0d5db;
  --grey2: #e7ebef;
  --grey1: #f5f5f5;
  --purple: #540087;
  --blue: #1253fa;
  --lightBlue: #edf8ff;
  --white: #ffffff;
  --brand: #7f56d9;
  --brandDark: #632ed5;
  --brandExtraLight: #baaed31f;
  --red: #ec0000;
  --orange: #ecb500;
  --primary: #7f56d9;
;
}
